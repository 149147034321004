import React, { useEffect, useState, useRef, } from 'react'

import { REQUESTS } from '../../../api/requests';

import IMAGES from '../../../config/images';

import styles from "../index.module.scss";
import Text from '../../../components/Text';

// const platforms = ["tizen", "webos", "roku", "android", "vidaa", "zeasn", "foxxum", "vizio", "tvos", "ios",];
const platforms = ["tizen", "webos", "roku", "android",];
const colors = [
    { model: 'tizen', color: "#008CD7" },
    { model: 'webos', color: "#B91134" },
    { model: 'roku', color: "#8755B6" },
    { model: 'android', color: "#23DE70" },
    { model: 'zeasn', color: "#ef7d39" },
    { model: 'foxxum', color: "#81da4c" },
    { model: 'vizio', color: "#F1581D" },
    { model: 'tvos', color: "#000000" },
    { model: 'ios', color: "#000000" },
    { model: 'vidaa', color: "#F03091" },
];

export default function DevicesByModelChart() {
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);

    const getDevicesByModelStatistics = () => {
        REQUESTS.DEVICES_BY_MODEL_STATISTICS((data) => {

            let totalCount = data.reduce((acc, item) => {
                return acc + item.count
            }, 0)

            setTotal(totalCount);

            const newData = platforms.map((item, index) => {
                for (let i = 0; i < data.length; i++) {
                    if (data[i].model === item) {
                        return {
                            ...data[i],
                            logo: IMAGES[item]
                        }
                    }
                    continue;
                }

                return { model: item, count: 0, logo: IMAGES[item] }
            });
            setData(newData)

        }, (err) => { })
    }

    useEffect(() => {
        getDevicesByModelStatistics()
    }, [])

    return (
        <div className={styles['chart']}>
            <h2 className={styles["title"]}><Text>Devices Count By Platform</Text></h2>
            <div className={styles['platforms-statistics']}>
                {
                    data.sort((a, b) => b.count - a.count).map((item, index) => {
                        let percent = total ? (item.count / total) * 100 : 0;

                        return <div key={index} className={styles['platform']}>
                            <div className={styles['model-logo']}>
                                <img src={item.logo} alt={item.model} />
                                <div className={styles["model-count"]}>
                                    {item.model} {item.count}
                                </div>
                            </div>
                            <div className={styles["statistic-percent"]}>
                                <div className={styles['statistic']} >
                                    <div className={styles['progress']} style={{ background: colors.find(c => c.model == item.model)?.color }} />
                                    <div style={{ width: `${percent}%`, background: colors.find(c => c.model == item.model)?.color }} className={styles['percent']} />
                                </div>
                                {percent && Math.round(percent)}%
                            </div>
                        </div>
                    })
                }
            </div>
        </div>
    )
}
