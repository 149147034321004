import React, { useEffect, useState } from 'react';

import { REQUESTS } from '../../api/requests';

import DevicesPieChart from './components/DevicesPieChart';
import DevicesByModelChart from './components/DevicesByModelChart';
import DevicesByCountryChart from './components/DevicesByCountryChart';
import PageTemplate from '../../components/PageTemplate';
import PageDescription from './components/PageDescription';

import ICONS from "../../config/icons";

import Text from '../../components/Text';

import styles from "./index.module.scss";

export default function ResellerDashboard() {
    const [devices, setDevices] = useState(null);

    const [subresellers, setSubresellers] = useState(null);

    const [credits, setCredits] = useState(null);

    const [totalDevices, setTotalDevices] = useState([]);

    const [open, setOpen] = useState(false);

    const getDevicesStatistics = () => {
        REQUESTS.DEVICES_STATISTICS((data) => {
            setDevices(data)
        }, (err) => { })
    }

    const getSubresellersStatistics = () => {
        REQUESTS.SUBRESELLERS_STATISTICS((data) => {
            setSubresellers(data)
        }, (err) => { })
    }

    const getCreditsStatistics = () => {
        REQUESTS.CREDITS_STATISTICS((data) => {
            setCredits(data)
        }, (err) => { })
    }

    const getTotalDevicesStatistics = () => {
        REQUESTS.APP_TOTAL_DEVICES_STATISTICS(setTotalDevices, (err) => { })
    }

    useEffect(() => {
        getDevicesStatistics()
        getSubresellersStatistics()
        getCreditsStatistics();
        getTotalDevicesStatistics();
    }, []);

    return (
        <PageTemplate title={<Text>Dashboard</Text>} setInfo={setOpen}>
            <div dir="auto" className={styles["page-content"]}>
                <div className={styles['cards-container']}>
                    <div
                        className={styles["card-style"]}
                        style={{
                            background: "#FFF5D926",
                            borderColor: "#FFBB38"
                        }}
                    >
                        {ICONS.CARD_TODAY_DEVICES}
                        <div className={styles['texts']}>
                            <p><Text>Today's Activated Devices</Text></p>
                            <span>{devices && devices.today_count}</span>
                        </div>

                    </div>
                    <div
                        className={styles["card-style"]}
                        style={{
                            borderColor: "#396AFF",
                            background: "linear-gradient(0deg, rgba(231, 237, 255, 0.15) 0%, rgba(231, 237, 255, 0.15) 100%), #FFF",
                        }}
                    >
                        {ICONS.CARD_TOTAL_DEVICES}
                        <div className={styles['texts']}>
                            <p><Text>Total Activated Devices</Text></p>
                            <span>{devices && devices.all_count}</span>

                        </div>
                    </div>
                    <div
                        className={styles["card-style"]}
                        style={{
                            background: "linear-gradient(0deg, rgba(255, 224, 235, 0.15) 0%, rgba(255, 224, 235, 0.15) 100%), #FFF",
                            borderColor: "#FF82AC"
                        }}
                    >
                        {ICONS.CARD_RESELLER}
                        <div className={styles['texts']}>
                            <p><Text>Resellers</Text></p>
                            <span>{subresellers && subresellers.all_count}</span>
                        </div>
                    </div>
                    <div
                        className={styles["card-style"]}
                        style={{
                            background: "#C1FFCF26",
                            borderColor: "#00DF31"
                        }}
                    >
                        {ICONS.CARD_CREDIT_SHARE}
                        <div className={styles['texts']}>
                            <p><Text>Total Credit Share</Text></p>
                            <span>{credits && credits.total_credit}</span>
                        </div>
                    </div>
                </div>
                <div className={styles['charts-container']}>
                    <DevicesPieChart data={totalDevices} />
                    <DevicesByModelChart />
                </div>
                <DevicesByCountryChart />
                <PageDescription
                    open={open}
                    setOpen={setOpen}
                />
            </div>
        </PageTemplate>
    )
}
