import React, { useEffect, useState } from 'react'

import ReactApexChart from 'react-apexcharts';

import useResize from "../../../hooks/use-resize";
import Text from '../../../components/Text';

import styles from "../index.module.scss";

export default function DevicesPieChart({ data }) {
    const resize = useResize();

    const [chartData, setChartData] = useState({
        series: [],
        options: {
            chart: {
                type: 'pie',
                height: 400
            },
            labels: [],
            colors: ['#2b7b64', '#e8ba46', '#c83c74', '#eb7b39', '#6049cd', '#9d62f5', '#5fd296','#ed415e','#2a8ff7', '#4253e5'],
            responsive: [{
                breakpoint: 600,
                options: {
                    legend: {
                        fontSize: '16px',
                        show: true,
                        position: resize <= 900 ? 'bottom' : "right",
                        horizontalAlign: "left"
                    }
                }

            }]
        }
    });

    useEffect(() => {
        if (data?.length) {
            let series = data.map((item) => item.device_count);
            let labels = data.map((item) => `${item.app_name} ${item.device_count}`);

            setChartData({
                ...chartData,
                series: series,
                options: {
                    ...chartData.options,
                    labels: labels
                }
            })
        }
    }, [data]);

    return (
        <div className={styles['chart']}>
            <h2 className={styles["title"]}><Text>Devices Count By App</Text></h2>
            <div className={styles['pie-chart']}>
                <ReactApexChart
                    options={chartData.options}
                    series={chartData.series}
                    type="pie"
                    height={400}
                    width="100%"
                />
            </div>
        </div>
    )
}
