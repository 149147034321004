import { useState } from 'react';

import useStore from '../../hooks/use-store';

import { HOST } from '../../config';

import ICONS from '../../config/icons';

import PageTemplate from '../../components/PageTemplate';

import PageDescription from './components/PageDescription';

import Text from '../../components/Text';

import styles from "./index.module.scss";

export default function AppsLinks() {
    const [apps] = useStore("apps");
    const [info, setInfo] = useState(false)

    return (
        <PageTemplate title={<Text>Apps & Links</Text>} setInfo={setInfo} >
            <div className={styles['apps-links']}>
                {
                    apps.map((app, index) => {
                        const { samsung_url, android_url, roku_url } = app;
                        return (
                            <div key={index} className={styles['application']}>
                                <div>
                                    <h3 className={styles['title']}>{app.name}</h3>
                                    <img className={styles['app-logo']} src={`${HOST}${app.logo}`} alt={app.name} />
                                </div>
                                <div>
                                    <h4 className={styles['download-title']}><Text>Download</Text></h4>
                                    <div className={styles['links']}>
                                        {samsung_url && <a href={samsung_url} download>{ICONS.DOWNLOAD}{ICONS.TIZEN}Samsung</a>}
                                        {android_url && <a href={android_url} download>{ICONS.DOWNLOAD}{ICONS.ANDROID} Android</a>}
                                        {roku_url && <a href={roku_url} target='_blank'>{ICONS.OPEN_ICON}{ICONS.ROKU}Roku</a>}
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
            <PageDescription
                open={info}
                setOpen={setInfo}
            />
        </PageTemplate>
    )
}
