import React, { useEffect, useState } from 'react';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';

import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  CloseOutlined,
  LogoutOutlined,
  InfoCircleOutlined
} from '@ant-design/icons';

import { Layout, Menu, theme, Button, Flex, Modal, Divider } from 'antd';

import classNames from 'classnames';

import Profile from './Profile';

import { REQUESTS } from '../api/requests';

import ICONS from '../config/icons';

import useUpdateProfile from '../hooks/use-update-profile';

import styles from "./layout.module.scss"
import LanguageDropdown from '../components/languageDropdown';
import Text from '../components/Text';
import useStore from '../hooks/use-store';
import TEXTS from '../config/resellerText';
import MyLogoCollapsed from '../assets/illustrations/demo-logo.png'
import MyLogoExpanded from '../assets/illustrations/logo.png'

const { Header, Content, Sider } = Layout;

const version = require('../version.json').version;

export default function LayoutComponent() {
  const location = useLocation();

  const navigate = useNavigate();

  const [collapsed, setCollapsed] = useState(window.innerWidth < 900);

  const [width, setWidth] = useState(window.innerWidth);

  const [modalOpen, setModalOpen] = useState(false);

  const [apiVersion, setApiVersion] = useState('');

  const [profile] = useUpdateProfile();

  const [language] = useStore("language")

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const getVersion = () => {
    REQUESTS.GET_VERSION(setApiVersion, () => { })
  }

  const logout = () => {

    if (modalOpen) return;

    setModalOpen(true);

    if (width < 900) {
      setCollapsed(true)
    }

    Modal.confirm({
      content: TEXTS["Do you want to logout?"][language],
      onOk() {
        localStorage.clear();
        navigate("/");
        setModalOpen(false);
      },
      onCancel() {
        setModalOpen(false);
      },
      okText: TEXTS["OK"][language],
      cancelText: TEXTS["Cancel"][language],
      forceRender: true
    });
  }

  useEffect(() => {

    setWidth(window.innerWidth);

    getVersion()

    if (window.innerWidth < 900) {
      setCollapsed(true)
    }

    window.addEventListener('resize', () => {
      setWidth(window.innerWidth);

      if (window.innerWidth < 900) {
        setCollapsed(true)
      } else {
        setCollapsed(false)
      }
    })
  }, []);

  return (
    <Layout>
      <Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        style={{
          overflow: 'auto',
          position: 'fixed',
          left: 0,
          top: 0,
          bottom: 0,
          transition: collapsed ? "none" : "all 0.2s",
          transform: width < 900 ?
            !collapsed ? "translateX(0px)" :
              "translateX(-100%)" :
            "translateX(0px)",
          zIndex: width < 900 ? 999999 : 0,
        }}>
        <Flex justify='space-between' gap={10} vertical style={{ minHeight: "100%" }}>
          <div>
            {width < 900 &&
              <CloseOutlined
                onClick={() => setCollapsed(true)}
                className={styles['close-icon']}
              />}
            <div className="demo-logo" style={{ width: collapsed ? 50 : 130 }} onClick={() => {
              navigate("/reseller/dashboard")
            }}>
              {collapsed ? <img src={MyLogoCollapsed} alt="Logo" style={{ width: '50px' }} /> : <img src={MyLogoExpanded} alt="Logo" style={{ width: '130px' }} />}

            </div>
            <Menu
              theme="dark"
              mode="inline"
              defaultSelectedKeys={["/reseller/dashboard"]}
              selectedKeys={[location.pathname]}
              items={[
                {
                  key: "/reseller/dashboard",
                  icon: ICONS.DASHBOARD,
                  label: <Link to={"/reseller/dashboard"}><Text>Dashboard</Text></Link>,
                },
                {
                  key: "/reseller/dashboard/resellers",
                  icon: ICONS.RESELLERS,
                  label: <Link to={"/reseller/dashboard/resellers"}><Text>Resellers</Text></Link>,
                },
                {
                  key: "/reseller/dashboard/devices",
                  icon: ICONS.DEVICES,
                  label: <Link to={"/reseller/dashboard/devices"}><Text>Devices</Text></Link>,
                },
                {
                  key: "/reseller/dashboard/activation",
                  icon: ICONS.ACTIVATION,
                  label: <Link to={"/reseller/dashboard/activation"}><Text>Activation</Text></Link>,
                },
                // {
                //   key: "/dashboard/multi-app-activation",
                //   icon: <FileSyncOutlined />,
                //   label: <Link to={"/dashboard/multi-app-activation"}>Multi App Activation</Link>,
                // },
                {
                  key: "/reseller/dashboard/check_mac",
                  icon: ICONS.CHECK_MAC,
                  label: <Link to={"/reseller/dashboard/check_mac"}><Text>Check Mac</Text></Link>,
                },
                {
                  key: "/reseller/dashboard/credits_history",
                  icon: ICONS.CREDITS_HISTORY,
                  label: <Link to={"/reseller/dashboard/credits_history"}><Text>Credits History</Text></Link>,
                },
                {
                  key: "/reseller/dashboard/apps_links",
                  icon: <InfoCircleOutlined style={{ fontSize: 20 }} />,
                  label: <Link to={"/reseller/dashboard/apps_links"}><Text>Apps & Links</Text></Link>,
                },
              ]}
              onClick={() => {
                if (width < 900) {
                  setCollapsed(true)
                }
              }}
              style={{
                background: "transparent",
                marginTop: 35,
                transition: "none !important",
              }}
            />
            <Divider />
            <div
              className={classNames(
                styles['menu-item-profile'],
                { [styles['active']]: location.pathname == "/reseller/dashboard/profile" })}
              style={{
                paddingLeft: profile?.name?.length >= 18 ? 10 : 24,
                justifyContent: collapsed ? "center" : "flex-start"
              }}
              onClick={() => {
                if (width < 900) {
                  setCollapsed(true)
                }
                navigate("/reseller/dashboard/profile")
              }}>
              {collapsed ?
                ICONS.PROFILE :
                <div>
                  {ICONS.PROFILE} <div className={styles['name']}>
                    {profile?.name || "Profile"}
                  </div>
                </div>}
            </div>
          </div>
          <div>
            <div className={styles["sider-bottom"]} >
              <div
                className={styles["note"]}
                style={{ flexDirection: collapsed ? "column" : "row" }}
              >
                {/* <Link to="https://t.me/123" target='_blank'>
                  {ICONS.TELEGRAM}
                </Link> */}
                {/* <Link to="https://wa.me/123" target='_blank'>
                  {ICONS.WHATS_UP}
                </Link> */}
              </div>
              <div onClick={logout} className={styles['logout-button']}>
                <LogoutOutlined className={styles['logout-icon']} />
                {!collapsed ? <Text>Logout</Text> : ""}
              </div>
              <div style={{ display: collapsed ? "none" : "block" }}>
                <Text>Resellers</Text>® 2024
              </div>
            </div>
          </div>
        </Flex>
      </Sider>
      <Layout
        style={{
          marginLeft: width < 900 ? 0 :
            collapsed ? 80 : 200,
          transition: "all 0.2s",
          minHeight: "100vh"
        }}>
        <Header
          style={{
            padding: width < 390 ? "0 5px" : "0 25px 0 10px",
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            background: "#ffffff",
            boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.03), 0 1px 6px -1px rgba(0, 0, 0, 0.02), 0 2px 4px 0 rgba(0, 0, 0, 0.02)",
            backdropFilter: "blur(10px)",
            zIndex: 99,
            position: "fixed",
            top: 0,
            right: 0,
            left: width < 900 ? 0 : collapsed ? 80 : 200,
            transition: "all 0.2s",
          }}
        >
          <Button
            type="text"
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={() => setCollapsed(!collapsed)}
          />
          <Flex gap={10} align='center'>
            <Profile />
            <LanguageDropdown />
          </Flex>
        </Header>
        <Content
          style={{
            margin: width < 900 ? '88px 5px 0' : '88px 16px 0',
            padding: width < 900 ? 10 : 24,
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
          }}
          id='ant-layout-content'
        >
          <Outlet />
        </Content>
      </Layout>
    </Layout >
  );
};