
import Auth_BG from '../assets/illustrations/illustration_dashboard.png';

import foxxum from '../assets/platform_logos/foxxum.png';
import tizen from "../assets/platform_logos/tizen.png";
import webos from "../assets/platform_logos/webos.png";
import android from "../assets/platform_logos/android.png";
import vidaa from "../assets/platform_logos/vidaa.png";
import zeasn from "../assets/platform_logos/zeasn.png";
import vizio from "../assets/platform_logos/vizio.png";
import tvos from "../assets/platform_logos/tvos.png";
import ios from "../assets/platform_logos/ios.png";
import roku from "../assets/platform_logos/roku.png";

// import en from "../assets/lang_flags/en.png";
// import br from '../assets/lang_flags/br.png';
// import ar from '../assets/lang_flags/ar.png';
import en from "../assets/lang_flags/en_circle.png";
import br from '../assets/lang_flags/br_circle.png';
import ar from '../assets/lang_flags/ar_circle.png';

const IMAGES = {
    Auth_BG,
    tizen,
    webos,
    android,
    vidaa,
    foxxum,
    zeasn,
    vizio,
    tvos,
    ios,
    roku,
    br,
    en,
    ar,
    // enCircle,
    // brCircle,
    // arCircle,
}
export default IMAGES

