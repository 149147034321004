import request from "./request";
import URLS from "./url";
import requestQuery from "./requestQuery";

export const REQUESTS = {
  RECAPCHA_SITE_KEY: (callback, errorCallback,) => {
    request(
      "get",
      URLS.RECAPCHA_SITE_KEY,
      {},
      callback,
      errorCallback,
    );
  },

  LOGIN: (body, callback, errorCallback) => {
    request(
      "post",
      URLS.LOGIN,
      body,
      callback,
      errorCallback,
    );
  },

  APPS: (query, callback, errorCallback) => {
    request(
      "get",
      URLS.APPS + requestQuery(query),
      {},
      callback,
      errorCallback,
    );
  },
  CREDITS: {
    ADD: (body, callback, errorCallback) => {
      request(
        "put",
        URLS.ADD_CREDIT,
        body,
        callback,
        errorCallback,
      );
    },
    TAKE: (body, callback, errorCallback) => {
      request(
        "put",
        URLS.TAKE_CREDIT,
        body,
        callback,
        errorCallback,
      );
    },
  },
  CREDIT_HISTORY: (params, callback, errorCallback) => {
    request(
      "get",
      URLS.CREDIT_HISTORY + requestQuery(params),
      {},
      callback,
      errorCallback,
    );
  },
  CHECK_MAC: (query, callback, errorCallback) => {
    request(
      "get",
      URLS.CHECK_MAC + requestQuery(query),
      {},
      callback,
      errorCallback,
    );
  },
  DEVICES: (params, callback, errorCallback) => {
    request(
      "get",
      URLS.DEVICES + requestQuery(params),
      {},
      callback,
      errorCallback,
    );
  },
  DEVICES_ACTIVATION: (body, callback, errorCallback) => {
    request(
      "post",
      URLS.DEVICES_ACTIVATION,
      body,
      callback,
      errorCallback,
    );
  },
  PROFILE: {
    GET: (callback, errorCallback,) => {
      request(
        "get",
        URLS.PROFILE,
        {},
        callback,
        errorCallback,
      );
    },
    UPDATE: (body, callback, errorCallback,) => {
      request(
        "put",
        URLS.PROFILE,
        body,
        callback,
        errorCallback,
      );
    },
  },
  PACKAGES: (query, callback, errorCallback,) => {
    request(
      "get",
      URLS.PACKAGES + requestQuery(query),
      {},
      callback,
      errorCallback,
    );
  },
  SUB_RESELLERS: {
    GET: (params, callback, errorCallback,) => {
      request(
        "get",
        URLS.SUB_RESELLERS + requestQuery(params),
        {},
        callback,
        errorCallback,
      );
    },
    CREATE: (body, callback, errorCallback,) => {
      request(
        "post",
        URLS.SUB_RESELLERS,
        body,
        callback,
        errorCallback,
      );
    },
    UPDATE: (body, callback, errorCallback,) => {
      request(
        "put",
        URLS.SUB_RESELLERS,
        body,
        callback,
        errorCallback,
      );
    },
  },
  PLAYLIST: {
    GET: (query, callback, errorCallback,) => {
      request(
        "get",
        URLS.PLAYLIST + requestQuery(query),
        {},
        callback,
        errorCallback,
      )
    },
    CREATE: (body, callback, errorCallback,) => {
      request(
        "post",
        URLS.PLAYLIST,
        body,
        callback,
        errorCallback,
      );
    },
    EDIT: (body, callback, errorCallback,) => {
      request(
        "put",
        URLS.PLAYLIST,
        body,
        callback,
        errorCallback,
      );
    },
    DELETE: (body, callback, errorCallback,) => {
      request(
        "delete",
        URLS.PLAYLIST,
        body,
        callback,
        errorCallback,
      );
    }
  },
  //STATISTICS
  DEVICES_STATISTICS: (callback, errorCallback,) => {
    request(
      "get",
      URLS.DEVICES_STATISTICS,
      {},
      callback,
      errorCallback,
    );
  },
  SUBRESELLERS_STATISTICS: (callback, errorCallback,) => {
    request(
      "get",
      URLS.SUBRESELLERS_STATISTICS,
      {},
      callback,
      errorCallback,
    );
  },
  CREDITS_STATISTICS: (callback, errorCallback,) => {
    request(
      "get",
      URLS.CREDITS_STATISTICS,
      {},
      callback,
      errorCallback,
    );
  },
  APP_TOTAL_DEVICES_STATISTICS: (callback, errorCallback,) => {
    request(
      "get",
      URLS.APP_TOTAL_DEVICES_STATISTICS,
      {},
      callback,
      errorCallback,
    );
  },
  DEVICES_BY_MODEL_STATISTICS: (callback, errorCallback,) => {
    request(
      "get",
      URLS.DEVICES_BY_MODEL_STATISTICS,
      {},
      callback,
      errorCallback,
    );
  },
  DEVICES_BY_COUNTRY_STATISTICS: (callback, errorCallback,) => {
    request(
      "get",
      URLS.DEVICES_BY_COUNTRY_STATISTICS,
      {},
      callback,
      errorCallback,
    );
  },

  GET_VERSION: (callback, errorCallback) => {
    request(
      "get",
      URLS.VERSION,
      {},
      callback,
      errorCallback,
    );
  }

};
