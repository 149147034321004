import { Routes, Route, } from "react-router-dom";
import { Helmet } from "react-helmet";

import { ConfigProvider } from "antd";

import { useScrollToTop } from "./hooks/use-scroll-to-top";

import Login from "./pages/auth";
import LayoutComponent from "./layouts/layout";
import Resellers from "./pages/resellers";
import Devices from "./pages/devices";
import CheckMac from "./pages/checkMac";
import Activation from "./pages/activation";
import ProfilePage from "./pages/profile";
import CreditsHistory from "./pages/creditsHistory";
import ResellerDashboard from "./pages/dashboard";
import TEXTS from "./config/resellerText";
import AppsLinks from "./pages/apps&links";
import useStore from "./hooks/use-store";

function App() {
  const [language, setLanguage] = useStore('language');
  useScrollToTop();

  return (<>
    <Helmet>
      <link rel="icon" href="./favicon.ico" />
      <link rel="apple-touch-icon" href="./favicon.ico" />
      <title>
        {TEXTS["Multi Player Reseller"][language]}
      </title>
      <meta property="og:title" content="Multi Player" />
      <meta property="og:site_name" content="Multi Player" />
      <meta property="og:description"
        content="Manage 10 apps from one panel IPTV media player for Roku, Samsung Tizen, LG WebOS, Android TV, & Whale OS devices.TVOS, iOS & Android Mobile soon" />
      <meta property="og:image" content="./favicon.ico" />
      <meta
        name="description"
        content={"Multi Player Reseller"}
      />
    </Helmet>
    <ConfigProvider theme={{
      token: {
        colorPrimary: '#429229',
        colorPrimaryBg: "#429229",
      },
      components: {
        Button: {
          colorPrimary: '#429229',
          algorithm: true,
          primaryShadow: "0 0 0 rgba(25, 55, 3, 0.23)",
          colorPrimaryBg: "#429229",
        },
        Menu: {
          colorPrimary: '#E4C30B',
        },
        Dropdown: {
          colorPrimary: '#fff',
        },
      }
    }}>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/reseller/dashboard" element={<LayoutComponent />}>
          <Route index element={<ResellerDashboard />} />
          <Route path="profile" element={<ProfilePage />} />
          <Route path="resellers" element={<Resellers />} />
          <Route path="devices" element={<Devices />} />
          <Route path="check_Mac" element={<CheckMac />} />
          <Route path="activation" element={<Activation />} />
          <Route path="credits_history" element={<CreditsHistory />} />
          <Route path="apps_links" element={<AppsLinks />} />
        </Route>
      </Routes>
    </ConfigProvider >
  </>);
}

export default App;
